export const extractInitialShifts = ({ time_slots = [], dataShifts = [] }) => {
  const updatedTimeSLots = time_slots?.map((timeSlot) => {
    const currentSlots = dataShifts?.filter((dataShift) => timeSlot?.day_of_week === dataShift?.day_of_week) ?? [];
    return {
      ...timeSlot,
      slots: currentSlots,
      is_selected: !!currentSlots?.length,
    };
  });
  return updatedTimeSLots ?? [];
};

export const makeUserShiftsPayload = ({ time_slots = [] }) => {
  return time_slots?.flatMap((slot) => slot?.slots)?.filter(Boolean) ?? [];
};
