import { Formik } from 'formik';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import * as yup from 'yup';
import { Group, Input, Select } from '../../../common';
import { formatOptionsForSelect, formatValueForSelect } from '../../../utils/filters-util';
import toast from 'react-hot-toast';
import { getErrorString, isEmptyObject } from '../../../utils';
import { createTeamApi, updateTeamApi } from '../../../api/teams-api';

const TeamInfoModalBody = forwardRef(
  (
    { teamId = '', teamData = {}, departments = [], users = [], setIsLoading = () => {}, onSuccess = () => {} },
    ref,
  ) => {
    const formikRef = useRef();
    const getValidationSchema = () =>
      yup.object().shape({
        name: yup.string().required('Name is required'),
        team_members: yup
          .array()
          .nullable()
          .test('roles', 'At least 1 member required', function (value) {
            return !!this?.parent?.team_members?.length > 0;
          }),
        department: yup.object().nullable().required('Department is required'),
        team_lead: yup.object().nullable().required('Team Lead is required'),
      });

    const getInitialValues = () => {
      if (!isEmptyObject(teamData)) {
        return {
          name: teamData?.name || '',
          department: teamData?.department ? departments?.find((item) => item?.slug === teamData?.department) : null,
          team_lead: teamData?.team_lead || null,
          team_members:
            teamData?.team_members?.map((member) => {
              return {
                ...member,
                id: member?.user?.id,
              };
            }) || [],
        };
      }

      return {
        name: '',
        department: null,
        team_lead: null,
        team_members: [],
      };
    };

    const getTeamMembersAttr = (team_members = []) => {
      const newTeamMembers = [...team_members];
      if (teamData?.team_members) {
        let updatedTeamMembers = teamData?.team_members?.map((member) => {
          const foundMember = newTeamMembers?.findIndex((newMember) => newMember?.id === member?.user?.id);
          if (foundMember !== -1) {
            newTeamMembers?.splice(foundMember, 1);
            return {
              id: member?.id,
            };
          }

          return {
            id: member?.id,
            _destroy: true,
          };
        });

        newTeamMembers?.forEach((newMember) => {
          updatedTeamMembers.push({ user_id: newMember?.id });
        });

        return updatedTeamMembers;
      }

      return (
        team_members?.map((item) => {
          return { user_id: item?.id };
        }) ?? []
      );
    };

    const handleFormSubmit = async (values) => {
      setIsLoading(true);
      try {
        const payload = {
          team: {
            name: values?.name ?? '',
            department: values?.department?.slug ?? '',
            team_lead_id: values?.team_lead?.id,
            team_members_attributes: getTeamMembersAttr(values?.team_members),
          },
        };
        const res = !teamId ? await createTeamApi(payload) : await updateTeamApi(teamId, payload);
        if (res && res?.status) {
          toast.success('Team Saved Successfully');
          onSuccess();
        } else {
          toast.error(getErrorString(res));
        }
      } catch (error) {
        toast.error(getErrorString(error));
      } finally {
        setIsLoading(false);
      }
    };

    useImperativeHandle(ref, () => ({
      submitForm() {
        formikRef?.current?.submitForm();
      },
    }));

    return (
      <Formik
        innerRef={formikRef}
        initialValues={getInitialValues()}
        validationSchema={getValidationSchema()}
        onSubmit={handleFormSubmit}
      >
        {(formikProps) => {
          const { values, handleSubmit, handleChange, handleBlur, setFieldValue, touched, errors } = formikProps;
          return (
            <form>
              <Group template={'repeat(2, 1fr)'}>
                <Input
                  placeholder="Enter Name"
                  name="name"
                  label="Name"
                  value={values?.name}
                  errorMsg={errors?.name && touched?.name && errors?.name}
                  onChange={handleChange}
                  onBlur={handleBlur('name')}
                  onPressEnter={handleSubmit}
                  required
                />
                <Select
                  label={'Department'}
                  placeholder={'Select Department'}
                  value={formatValueForSelect({
                    obj: values.department,
                    key: 'name',
                  })}
                  options={formatOptionsForSelect({ list: departments, setAsSlug: true })}
                  onChange={(option) => setFieldValue('department', option)}
                  onBlur={handleBlur('department')}
                  errorMsg={errors?.department && touched?.department && errors?.department}
                  allowClear
                  onClear={() => setFieldValue('department', null)}
                  required
                />
                <Select
                  label={'Team Lead'}
                  placeholder={'Select Lead'}
                  value={formatValueForSelect({
                    obj: values.team_lead,
                    key: 'name',
                  })}
                  options={formatOptionsForSelect({ list: users })}
                  onChange={(option) => {
                    setFieldValue('team_lead', option);
                  }}
                  onBlur={handleBlur('team_lead')}
                  errorMsg={errors?.team_lead && touched?.team_lead && errors?.team_lead}
                  required
                />
                <Select
                  label={'Members'}
                  placeholder={'Select Members'}
                  value={formatOptionsForSelect({ list: values.team_members })}
                  options={formatOptionsForSelect({ list: users })}
                  onChange={(options) => {
                    setFieldValue('team_members', [...options]);
                  }}
                  onBlur={handleBlur('team_members')}
                  errorMsg={errors?.team_members && touched?.team_members && errors?.team_members}
                  mode={'multiple'}
                  required
                />
              </Group>
            </form>
          );
        }}
      </Formik>
    );
  },
);

export default TeamInfoModalBody;
