import React from 'react';
import styles from './TimeLine.module.scss';
import cx from 'clsx';
import { isEmptyArray, isEmptyObject } from '../../utils';
import { Empty, Spin } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';

const TimelineEntry = ({ entry, getIcon, TimeLineBodyComponent }) => (
  <div className="d-flex">
    <div className={cx('d-inline-flex justify-content-center align-items-center mt-2', styles.timelineRound)}>
      {getIcon(entry)}
    </div>
    <div className={cx(styles.TimeLineBody, 'w-100')}>
      {TimeLineBodyComponent ? (
        <TimeLineBodyComponent data={{ ...entry }} />
      ) : (
        <div className={cx('d-flex justify-content-between', styles.scheduleTime)}>
          <div>{entry?.description ?? ''}</div>
          <div>{entry?.text ?? ''}</div>
        </div>
      )}
    </div>
  </div>
);

const Timeline = ({
  className,
  icon,
  bgColor,
  containerSize,
  borderColor,
  borderRadius,
  style,
  TimeLineBodyComponent = null,
  timeLineData = [],
  iconWidth,
  iconBgColor,
  getBodyIcon,
  pagination = {},
  fetchMoreData = null,
  maxHeight = '450px',
}) => {
  const getIcon = (item = {}) => {
    if (getBodyIcon) {
      return getBodyIcon(item);
    }
    return icon;
  };

  if (isEmptyArray(timeLineData)) return <Empty />;

  return (
    <div
      className={cx({ [styles.TimelineContainer]: isEmptyObject(pagination) })}
      style={{
        ...style,
        '--round-icon-width': iconWidth,
        '--icon-bg-color': !!icon ? 'transparent' : iconBgColor,
        '--icon-color': 'inherit',
      }}
    >
      {!isEmptyObject(pagination) && fetchMoreData ? (
        <InfiniteScroll
          dataLength={timeLineData?.length}
          next={fetchMoreData}
          hasMore={pagination?.page !== pagination?.pages}
          style={{ maxHeight: maxHeight }}
          height="100%"
          className={cx(styles.TimelineContainer)}
          loader={<Spin rootClassName="d-flex justify-content-center" />}
        >
          {timeLineData?.map((entry, index) => (
            <TimelineEntry key={index} entry={entry} getIcon={getIcon} TimeLineBodyComponent={TimeLineBodyComponent} />
          ))}
        </InfiniteScroll>
      ) : (
        timeLineData?.map((entry, index) => (
          <TimelineEntry key={index} entry={entry} getIcon={getIcon} TimeLineBodyComponent={TimeLineBodyComponent} />
        ))
      )}
    </div>
  );
};

export default Timeline;
