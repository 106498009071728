import React, { forwardRef, useImperativeHandle } from 'react';
import toast from 'react-hot-toast';
import { getErrorString } from '../../../utils';
import { deleteTeamApi } from '../../../api/teams-api';

const DeleteTeamModalBody = forwardRef(({ teamId = '', onSuccess = () => {}, setIsLoading = () => {} }, ref) => {
  const onSubmit = async () => {
    if (!teamId) return toast.error('Can not delete team, Corrupt team data!');
    setIsLoading(true);
    try {
      const res = await deleteTeamApi(teamId);
      if (res && res?.status) {
        toast.success('Team deleted successfully');
        onSuccess();
      } else {
        toast.error(getErrorString(res));
      }
    } catch (error) {
      toast.error(getErrorString(error));
    } finally {
      setIsLoading(false);
    }
  };

  useImperativeHandle(ref, () => ({
    submitForm() {
      onSubmit();
    },
  }));

  return <div className="subtitle-2">{'Are you sure you want to delete this user!'}</div>;
});

export default DeleteTeamModalBody;
