import React from 'react';
import { DatePicker, Space, TimePicker } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

const { RangePicker } = DatePicker;

const DateAndTimePicker = ({
  value,
  onChange,
  showTime,
  picker,
  format = 'YYYY-MM-DD HH:mm:ss',
  disabledDate,
  disabledDateTime,
  label,
  variant,
  rowContainerClassName,
  showNow = false,
  allowClear = true,
  ...props
}) => {
  const datePickerProps = {
    value: value ? dayjs(value, format) : null,
    onChange: onChange,
    format: showTime ? format : undefined,
    showTime: showTime ? { format: 'HH:mm:ss', ...showTime } : undefined,
    disabledDate,
    disabledTime: disabledDateTime,
    variant: variant,
    showNow: showNow,
    allowClear: allowClear,
    ...props,
  };

  return (
    <Space direction="vertical" size={12}>
      {picker === 'range' ? (
        <RangePicker {...datePickerProps} />
      ) : picker === 'time' ? (
        <TimePicker {...datePickerProps} />
      ) : (
        <DatePicker {...datePickerProps} />
      )}
    </Space>
  );
};

export default DateAndTimePicker;
