import React, { Fragment, useRef, useState } from 'react';
import { Button, Modal } from '../../../common';
import { getErrorString } from '../../../utils';
import { createPatientContactApi } from '../../../api/patients-api';
import toast from 'react-hot-toast';
import { usePatientContactsContext } from '../../../contexts/patient-contact-context';
import PatientContactModalBody from './PatientContactModalBody';
import Permission from '../../permission/Permission';
import { PERMISSIONS_STRUCT } from '../../../constants/permissions';

const CreatePatientContactModal = ({ patientId = '', onSuccessCreate = () => {} }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { contactTypes } = usePatientContactsContext();
  const formRef = useRef();

  const handleSubmit = async (values) => {
    if (!values?.type?.id || !patientId) return;
    try {
      setLoading(true);
      const payload = {
        contact: {
          patient_id: patientId,
          type_id: values?.type?.id,
          source_id: values?.id,
        },
      };

      const res = await createPatientContactApi(payload);
      if (res && res?.status) {
        res?.data?.contacts && onSuccessCreate(res?.data?.contacts);
        toast.success('Contact Created Successfully');
        setModalOpen(false);
      } else {
        toast.error(getErrorString(res));
      }
    } catch (error) {
      toast.error(getErrorString(error));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <Permission models={PERMISSIONS_STRUCT.CONTACT.NAME} actions={PERMISSIONS_STRUCT.CONTACT.ACTIONS.CREATE}>
        <Button
          className={''}
          text={''}
          size={'medium'}
          icon={'BsPlus'}
          onClick={() => setModalOpen(true)}
        />
      </Permission>

      <Modal
        title="Create Patient Contact"
        open={modalOpen}
        ModalBody={
          <PatientContactModalBody ref={formRef} handleSubmit={handleSubmit} patientContactTypes={contactTypes} />
        }
        handleCancel={() => setModalOpen(false)}
        handleOk={() => {
          formRef?.current?.submitForm();
        }}
        okButtonProps={{ loading }}
        okText="Create"
        showActions={true}
        destroyOnClose={true}
        width={700}
      />
    </Fragment>
  );
};

export default CreatePatientContactModal;
