import { useState, useEffect } from 'react';
import { fetchUsersListApi } from '../../api/users-api';
import toast from 'react-hot-toast';
import { getErrorString } from '../../utils';

const useUsersData = ({
  fetchInternal = true,
  fetchInternalDeps = [],
  showToast = false,
  paramsObj = {},
  paramsStr = '',
}) => {
  const [allUsers, setAllUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const res = await fetchUsersListApi(paramsStr, paramsObj);
      if (res && res?.status) {
        setAllUsers(res?.data ?? []);
      } else {
        showToast && toast.error(getErrorString(res));
        setError(getErrorString(res));
      }
    } catch (error) {
      showToast && toast.error(getErrorString(error));
      setError(getErrorString(error));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (fetchInternal) {
      fetchUsers();
    }
  }, [...fetchInternalDeps]);

  return {
    allUsers,
    loading,
    error,
    fetchUsers,
  };
};

export default useUsersData;
