import dayjs from 'dayjs';

export const DATE_RANGE_PRESETS = [
  {
    label: 'Yesterday',
    value: [dayjs().subtract(1, 'day').startOf('day'), dayjs().subtract(1, 'day').endOf('day')],
  },
  {
    label: 'Last 7 days',
    value: [dayjs().subtract(6, 'days').startOf('day'), dayjs().endOf('day')],
  },
  {
    label: 'Last 30 days',
    value: [dayjs().subtract(29, 'days').startOf('day'), dayjs().endOf('day')],
  },
  {
    label: 'Last 60 days',
    value: [dayjs().subtract(59, 'days').startOf('day'), dayjs().endOf('day')],
  },
  {
    label: 'Last 90 days',
    value: [dayjs().subtract(89, 'days').startOf('day'), dayjs().endOf('day')],
  },
  {
    label: 'This Month',
    value: [dayjs().startOf('month'), dayjs().endOf('month')],
  },
  {
    label: 'Last 6 Months',
    value: [dayjs().subtract(6, 'month').startOf('day'), dayjs().endOf('day')],
  },
  {
    label: 'Last Year',
    value: [dayjs().subtract(12, 'month').startOf('day'), dayjs().endOf('day')],
  },
];

export const DATE_FORMATS = {
  dayMonthDateYear: 'ddd, MMMM D, YYYY',
  monthDateYear: 'MMM D, YYYY',
  timePayloadFormat: 'YYYY-MM-DDTHH:mm:ss',
  dayMonthYearFilters: 'DD MMM YYYY',
  weekMonthDayYear: 'dddd, MMMM D, YYYY',
  hourDayMonthYearTime: 'h:00 A, DD MMM, YYYY',
  hourMinuteDayMonthYearTime: 'h:mm A, DD MMM, YYYY',
  dayMonthYearTime: 'DD MMM, YYYY, h:mm A',
  monthDate: 'MMM DD',
};

export const LAST_6_MONTHS_DATE = {
  label: 'Last 3 Months',
  value: [
    dayjs().subtract(3, 'month').startOf('month').format(DATE_FORMATS.timePayloadFormat),
    dayjs().endOf('date').format(DATE_FORMATS.timePayloadFormat),
  ],
};

export const WEEK_DAYS_SLUGS = {
  MONDAY: 'monday',
  TUESDAY: 'tuesday',
  WEDNESDAY: 'wednesday',
  THURSDAY: 'thursday',
  FRIDAY: 'friday',
  SATURDAY: 'saturday',
  SUNDAY: 'sunday',
};

export const DEFAULT_SHIFT_TIME = {
  START_TIME: dayjs().hour(9).minute(0).second(0).format('YYYY-MM-DDTHH:mm:ssZ'),
  END_TIME: dayjs().hour(18).minute(0).second(0).format('YYYY-MM-DDTHH:mm:ssZ'),
};

export const INITIAL_USER_SHIFT_LIST = [
  {
    day_of_week: WEEK_DAYS_SLUGS.MONDAY,
    slots: [],
    is_selected: false,
  },
  {
    day_of_week: WEEK_DAYS_SLUGS.TUESDAY,
    slots: [],
    is_selected: false,
  },
  {
    day_of_week: WEEK_DAYS_SLUGS.WEDNESDAY,
    slots: [],
    is_selected: false,
  },
  {
    day_of_week: WEEK_DAYS_SLUGS.THURSDAY,
    slots: [],
    is_selected: false,
  },
  {
    day_of_week: WEEK_DAYS_SLUGS.FRIDAY,
    slots: [],
    is_selected: false,
  },
  {
    day_of_week: WEEK_DAYS_SLUGS.SATURDAY,
    slots: [],
    is_selected: false,
  },
  {
    day_of_week: WEEK_DAYS_SLUGS.SUNDAY,
    slots: [],
    is_selected: false,
  },
];
