import React, { useRef } from 'react';
import { Formik } from 'formik';
import { Flex } from 'antd';
import * as yup from 'yup';
import toast from 'react-hot-toast';
import { getErrorString } from '../../../utils';
import { useNavigate } from 'react-router-dom';
import { createPatient, updatePatientDetail } from '../../../api/patients-api';
import { Button, Card, Input } from '../../../common';

const PatientInfoForm = ({ patientId = '', prefillInfo = {} }) => {
  const formRef = useRef();
  const navigate = useNavigate();
  const getInitialFormValues = ({ editData = {} }) => {
    return {
      first_name: editData?.first_name || '',
      last_name: editData?.last_name || '',
      samcart_id: editData?.samcart_customer_id || '',
      mdi_patient_id: editData?.mdi_patient_id || '',
      email: editData?.email || '',
      phone: editData?.phone_number || '',
      secondary_email: editData?.secondary_email || '',
      secondary_phone_number: editData?.secondary_phone_number || '',
    };
  };

  const getValidationSchema = () => {
    return yup.object().shape({
      first_name: yup.string().required('First Name is required'),
      last_name: yup.string().required('Last Name is required'),
      samcart_id: yup
        .string()
        .nullable()
        .test('samcart_test', 'A max length of 19 characters is allowed', (value) => {
          return !value || value?.length < 20;
        }),
      email: yup.string().required('Email is required').email('Valid Email is required'),
      phone: yup.string().required('Phone is required'),
      secondary_email: yup.string().email('Valid Email is required'),
    });
  };

  const handlePatientSubmitCallback = async (values) => {
    try {
      const payload = {
        patient: {
          samcart_customer_id: values?.samcart_id,
          mdi_patient_id: values?.mdi_patient_id,
          email: values?.email,
          first_name: values?.first_name,
          last_name: values?.last_name,
          phone_number: values?.phone,
          secondary_email: values?.secondary_email,
          secondary_phone_number: values?.secondary_phone_number,
        },
      };
      const res = patientId ? await updatePatientDetail(payload, patientId) : await createPatient(payload);
      if (res && res?.status) {
        toast.success(res?.message);
        navigate(-1, { replace: true });
      } else {
        toast.error(getErrorString(res));
      }
    } catch (error) {
      toast.error(getErrorString(error));
    }
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={getInitialFormValues({ editData: prefillInfo })}
      validationSchema={getValidationSchema()}
      onSubmit={handlePatientSubmitCallback}
    >
      {(formikProps) => {
        const { values, errors, touched, handleSubmit, handleBlur, handleChange, isSubmitting } = formikProps;

        return (
          <form>
            <Card>
              <Flex vertical gap={25}>
                <Input
                  name="first_name"
                  placeholder="First Name"
                  label="First Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.first_name}
                  errorMsg={errors?.first_name && touched?.first_name && errors.first_name}
                />
                <Input
                  name="last_name"
                  placeholder="Last Name"
                  label="Last Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.last_name}
                  errorMsg={errors?.last_name && touched?.last_name && errors.last_name}
                />
                <Input
                  name="samcart_id"
                  placeholder="Samcart Id"
                  label="Samcart Customer ID"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.samcart_id}
                  errorMsg={errors?.samcart_id && touched?.samcart_id && errors.samcart_id}
                />
                <Input
                  name="mdi_patient_id"
                  placeholder="MDI Patient Id"
                  label="MDI Customer ID"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.mdi_patient_id}
                  errorMsg={errors?.mdi_patient_id && touched?.mdi_patient_id && errors.mdi_patient_id}
                />
                <Input
                  name="email"
                  placeholder="Email"
                  label="Email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.email}
                  errorMsg={errors?.email && touched?.email && errors.email}
                />
                <Input
                  name="phone"
                  placeholder="Phone number"
                  label="Patient Phone Number"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.phone}
                  errorMsg={errors?.phone && touched?.phone && errors.phone}
                />
                <Input
                  name="secondary_email"
                  placeholder="Secondary Email"
                  label="Secondary Email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.secondary_email}
                  errorMsg={errors?.secondary_email && touched?.secondary_email && errors.secondary_email}
                />
                <Input
                  name="secondary_phone_number"
                  placeholder="Secondary Phone number"
                  label="Secondary Phone Number"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.secondary_phone_number}
                  errorMsg={
                    errors?.secondary_phone_number && touched?.secondary_phone_number && errors.secondary_phone_number
                  }
                />
                <Flex justify="end">
                  <Button
                    text="Submit"
                    type={'primary'}
                    size={'large'}
                    style={{ '--ant-button-padding-inline-lg': '36px' }}
                    onClick={handleSubmit}
                    loading={isSubmitting}
                  />
                </Flex>
              </Flex>
            </Card>
          </form>
        );
      }}
    </Formik>
  );
};

export default PatientInfoForm;
