import React, { useEffect, useState } from 'react';
import { Card, Icon, Spinner } from '../../common';
import { Flex } from 'antd';
import toast from 'react-hot-toast';
import { getErrorString } from '../../utils';
import { fetchExternalCommunicationApi } from '../../api/communications-api';
import Timeline from '../../common/timeline/TimeLine';
import ExternalLogDetailCard from './ExternalLogDetailCard';
import { LOG_TYPES } from '../../constants/communication-constants';
import { appendParamsToQueryString } from '../../utils/filters-util';

const ExternalLogs = ({ paramsStr = '' }) => {
  const [pagination, setPagination] = useState({});
  const [logsData, setLogsData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchExternalLogs = async () => {
    setLoading(true);
    try {
      const res = await fetchExternalCommunicationApi(paramsStr);
      if (res && res?.status) {
        setLogsData(res?.data ?? []);
        setPagination(res?.meta?.pagination ?? {});
      }
    } catch (error) {
      toast.error(getErrorString(error));
    } finally {
      setLoading(false);
    }
  };

  const getLogIcon = (item = {}) => {
    switch (item?.delivery_method) {
      case LOG_TYPES.EMAIL:
        return <Icon icon={'CiMail'} iconSize={'1.5em'} />;
      case LOG_TYPES.SMS:
        return <Icon icon={'PiChatCenteredTextThin'} iconSize={'1.5em'} color={'#4CAF50'} />;
      default:
        return null;
    }
  };

  const fetchExtraLogs = async () => {
    const nextPage = pagination?.page + 1;
    try {
      const updatedParams = appendParamsToQueryString('page', nextPage, paramsStr);
      const res = await fetchExternalCommunicationApi(updatedParams);
      if (res && res?.status) {
        setLogsData([...logsData, ...res?.data]);
        setPagination(res?.meta?.pagination ?? {});
      }
    } catch (error) {
      toast.error(getErrorString(error));
    }
  };

  useEffect(() => {
    fetchExternalLogs();
  }, [paramsStr]);

  if (loading) return <Spinner size="small" color="var(--ant-color-primary)" />;

  return (
    <Card>
      <Timeline
        maxHeight="590px"
        iconWidth={'1.5em'}
        iconBgColor={'transparent'}
        timeLineData={logsData}
        pagination={pagination}
        fetchMoreData={fetchExtraLogs}
        TimeLineBodyComponent={ExternalLogDetailCard}
        getBodyIcon={getLogIcon}
      />
    </Card>
  );
};

export default ExternalLogs;
