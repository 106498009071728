import React from 'react';
import cx from 'clsx';
import { Select as AntdSelect } from 'antd';
import styles from './Select.module.scss';
import Label from '../label/Label';
import ErrorMessage from '../error-message/ErrorMessage';
import Skeleton from '../skeleton/Skeleton';
import { FaChevronDown } from 'react-icons/fa6';

const Select = ({
  value = '',
  placeholder = '',
  options = [],
  style,
  label = '',
  errorMsg = '',
  onChange = () => {},
  onClear = () => {},
  onBlur = () => {},
  allowClear = false,
  disabled = false,
  className = '',
  mode = 'single',
  loading = false,
  required = false,
  labelSize,
  labelClassName = '',
  selectSize = 'large',
  loaderHeight = 42,
  ...rest
}) => {
  return (
    <div>
      {label && (
        <Label className={labelClassName} required={required} labelSize={labelSize}>
          {label}
        </Label>
      )}

      {loading ? (
        <Skeleton height={loaderHeight} />
      ) : (
        <AntdSelect
          value={value || undefined}
          placeholder={placeholder}
          options={options}
          style={style}
          onChange={(e, items) => e && onChange(items)}
          onClear={onClear}
          onBlur={onBlur}
          allowClear={allowClear}
          disabled={disabled}
          className={cx(styles.select, className)}
          mode={mode}
          loading={loading}
          size={selectSize}
          maxTagCount={'responsive'}
          suffixIcon={<FaChevronDown color="var(--gray-300)" />}
          {...rest}
        />
      )}

      {errorMsg && <ErrorMessage errorMessage={errorMsg} />}
    </div>
  );
};

export default Select;
