import React, { Fragment } from 'react';
import { Button, DateAndTimePicker, Heading, Icon } from '../../../common';
import { Col, Flex, Row } from 'antd';
import dayjs from 'dayjs';
import { DATE_FORMATS, DEFAULT_SHIFT_TIME } from '../../../constants/date-constants';

const UserShiftsInput = ({ values, setFieldValue = () => {}, loading = false }) => {
  const getSelectedAll = () => {
    if (!values?.time_slots || !Array.isArray(values.time_slots)) {
      return false;
    }
    return values.time_slots.every((slot) => slot.is_selected === true);
  };

  const getCurrenAvailableSlots = (timeSlot = {}) => {
    if (!timeSlot?.is_selected || !timeSlot?.slots?.length) return [];
    return timeSlot?.slots?.filter((slot) => !slot?._destroy);
  };

  const setDayNewSlot = (timeSlot = {}, timeSlotIndex = 0) => {
    const newSlot = {
      start_time: DEFAULT_SHIFT_TIME.START_TIME,
      end_time: DEFAULT_SHIFT_TIME.END_TIME,
      day_of_week: timeSlot?.day_of_week,
    };
    const updatedSlots = [...timeSlot?.slots, newSlot]?.filter(Boolean);
    setFieldValue(`time_slots.${timeSlotIndex}.slots`, updatedSlots);
  };

  const removeExtraSlots = (timeSlot = {}, timeSlotIndex = 0) => {
    const updatedSlots =
      timeSlot?.slots
        ?.filter((slot) => !!slot?.id)
        ?.map((slot) => {
          return {
            ...slot,
            _destroy: true,
          };
        }) ?? [];
    setFieldValue(`time_slots.${timeSlotIndex}.slots`, updatedSlots);
  };

  const handleOnClickDayChange = (timeSlot = {}, timeSlotIndex = 0) => {
    if (timeSlot?.is_selected) {
      removeExtraSlots(timeSlot, timeSlotIndex);
      setFieldValue(`time_slots.${timeSlotIndex}.is_selected`, false);
    } else {
      setDayNewSlot(timeSlot, timeSlotIndex);
      setFieldValue(`time_slots.${timeSlotIndex}.is_selected`, true);
    }
  };

  const removeCurrenSlot = (timeSlot = {}, timeSlotIndex = 0, subSlotIndex = 0) => {
    if (!timeSlot?.slots?.[subSlotIndex]?.id) {
      const currentSlots = [...timeSlot?.slots];
      currentSlots?.splice(subSlotIndex, 1);
      setFieldValue(`time_slots.${timeSlotIndex}.slots`, currentSlots);
    } else {
      const updatedSubSlot = {
        ...timeSlot?.slots?.[subSlotIndex],
        _destroy: true,
      };
      setFieldValue(`time_slots.${timeSlotIndex}.slots.${subSlotIndex}`, updatedSubSlot);
    }
  };

  const copyCurrentSlotForAll = (currentSlot = {}) => {
    const updatedSlots = values?.time_slots?.map((daySlot) => {
      return {
        ...daySlot,
        slots: daySlot?.slots?.map((slot) => {
          return {
            ...slot,
            start_time: currentSlot?.start_time,
            end_time: currentSlot?.end_time,
          };
        }),
      };
    });
    setFieldValue(`time_slots`, updatedSlots);
  };

  const handleOnClickSelectAll = () => {
    if (getSelectedAll()) {
      values?.time_slots?.forEach((timeSlot, timeSlotIndex) => {
        handleOnClickDayChange(timeSlot, timeSlotIndex);
      });
    } else {
      values?.time_slots?.forEach((timeSlot, timeSlotIndex) => {
        if (!timeSlot?.is_selected) handleOnClickDayChange(timeSlot, timeSlotIndex);
      });
    }
  };

  return (
    <Fragment>
      <Heading level={3} className={'heading-3'}>
        {'User Shifts'}
      </Heading>
      <Flex gap={15}>
        {values?.time_slots?.map((timeSlot, dayIndex) => (
          <Button
            loading={loading}
            key={timeSlot?.day_of_week}
            type={'default'}
            shape={'round'}
            text={timeSlot?.day_of_week}
            className={timeSlot?.is_selected ? 'selected capitalize' : 'capitalize'}
            onClick={() => handleOnClickDayChange(timeSlot, dayIndex)}
          />
        ))}
        <Button
          loading={loading}
          type={'default'}
          shape={'round'}
          text={getSelectedAll() ? 'Unselect All' : 'Select All'}
          className={getSelectedAll() ? 'selected capitalize' : 'capitalize'}
          onClick={() => handleOnClickSelectAll()}
        />
      </Flex>
      <Flex vertical gap={30}>
        {values?.time_slots?.map((timeSlot, timeSlotIndex) => {
          const currentSlots = getCurrenAvailableSlots(timeSlot);
          if (!currentSlots?.length) return null;
          return (
            <Row key={timeSlot?.day_of_week} align="top" gutter={16}>
              <Col span={4} className="mt-2">
                <div className="capitalize heading-4 px-2">{timeSlot?.day_of_week}</div>
              </Col>
              <Col span={12}>
                <Flex vertical gap={20}>
                  {currentSlots?.map((slot, slotIndex) => (
                    <Flex key={slot?.id ?? slotIndex} gap={15} align="center">
                      <Flex gap={40} align="center">
                        <DateAndTimePicker
                          picker={'time'}
                          allowClear={false}
                          use12Hours
                          format="h:mm A"
                          value={slot?.start_time ? dayjs(slot?.start_time) : null}
                          showTime={{
                            format: 'HH:mm',
                            minuteStep: 5,
                          }}
                          onChange={(value) => {
                            const startTime = value ? dayjs(value).format(DATE_FORMATS.timePayloadFormat + 'Z') : null;
                            setFieldValue(`time_slots.${timeSlotIndex}.slots.${slotIndex}.start_time`, startTime);
                          }}
                        />
                        <div className="text-2">{'To'}</div>
                        <DateAndTimePicker
                          picker={'time'}
                          allowClear={false}
                          use12Hours
                          format="h:mm A"
                          value={slot?.end_time ? dayjs(slot?.end_time) : null}
                          showTime={{
                            format: 'HH:mm',
                            minuteStep: 5,
                          }}
                          onChange={(value) => {
                            const endTime = value ? dayjs(value).format(DATE_FORMATS.timePayloadFormat + 'Z') : null;
                            setFieldValue(`time_slots.${timeSlotIndex}.slots.${slotIndex}.end_time`, endTime);
                          }}
                        />
                      </Flex>
                      {slotIndex !== 0 ? (
                        <Button
                          icon={'IoTrashBinOutline'}
                          iconSize={'1em'}
                          onClick={() => removeCurrenSlot(timeSlot, timeSlotIndex, slotIndex)}
                        />
                      ) : (
                        <Button icon={'IoCopyOutline'} iconSize={'1em'} onClick={() => copyCurrentSlotForAll(slot)} />
                      )}
                    </Flex>
                  ))}
                  <Flex justify="start">
                    <Button
                      icon={'BsPlus'}
                      text={'Add time'}
                      type={'link'}
                      onClick={() => setDayNewSlot(timeSlot, timeSlotIndex)}
                    />
                  </Flex>
                </Flex>
              </Col>
            </Row>
          );
        })}
      </Flex>
    </Fragment>
  );
};

export default UserShiftsInput;
