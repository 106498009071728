import APIController from '../core-api-controller';

export const fetchTeamsListApi = (paramsObj = {}, paramsStr = '') => {
  const path = `${APIController.endpointApi}/teams${paramsStr ? paramsStr : ''}`;
  return APIController.getApiCallback(path, paramsObj);
};

export const createTeamApi = (data = {}) => {
  const path = `${APIController.endpointApi}/teams`;
  return APIController.postCallback(path, data);
};

export const updateTeamApi = (teamId = '', data = {}) => {
  const path = `${APIController.endpointApi}/teams/${teamId}`;
  return APIController.putCallback(path, data);
};

export const deleteTeamApi = (teamId = '') => {
  const path = `${APIController.endpointApi}/teams/${teamId}`;
  return APIController.deleteCallback(path);
};
