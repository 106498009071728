import React, { Fragment } from 'react';
import MetricStats from '../metric-stats/MetricStats';
import { Flex } from 'antd';
import { DASHBOARD_TAB_SLUGS } from '../../../constants/dashboard-constants';

const AnalyticMetrics = () => {
  return (
    <Fragment>
      <Flex vertical gap={20}>
        <MetricStats dashboardType={DASHBOARD_TAB_SLUGS.ANALYTICS} />
      </Flex>
    </Fragment>
  );
};

export default AnalyticMetrics;
