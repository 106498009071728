import { DATE_FORMATS } from '../date-constants';

export const DASHBOARD_TAB_SLUGS = {
  PERFORMANCE_TAB: 'orders_and_cases',
  ORDERS_TAB: 'orders',
  CASES_TAB: 'cases',
  PRODUCTS_TAB: 'products',
  LEADS_TAB: 'leads',
  ANALYTICS: 'analytics',
};

export const DATEITEMFILTER = {
  type: 'date',
  format: DATE_FORMATS.monthDateYear,
  customKeys: ['from', 'to'],
  placeholder: 'Created at',
  showRangePreset: true,
};

export const DASHBOARD_SUB_TAB_SLUGS = {
  STATS: 'stats',
  CHART: 'chart',
  TABLE: 'table',
};
