import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Layout } from '../components';
import ProtectedRoute from '../components/protected-route/ProtectedRoute';
import NotFound from '../pages/404/not_found';
import {
  BASE_PATH,
  CASES_PATH,
  CASE_DETAIL_PATH,
  LEADS_PATH,
  LEAD_DETAIL_PATH,
  ORDERS_PATH,
  PATIENTS_PATH,
  PATIENT_CREATE_PATH,
  PATIENT_DETAIL_PATH,
  PATIENT_EDIT_PATH,
  PRODUCTS_PATH,
  QUESTIONNAIRES_PATH,
  STATUSES_PATH,
  STATUS_EDIT_PATH,
  SUBSCRIPTIONS_PATH,
  TEAMS_PATH,
  TICKETS_PATH,
  USERS_PATH,
  USER_CREATE_PATH,
  USER_EDIT_PATH,
  VOUCHERS_PATH,
} from '../constants/admin-paths';
import {
  Cases,
  Dashboard,
  LeadDetail,
  Leads,
  Orders,
  PatientCreate,
  PatientEdit,
  PatientDetail,
  Patients,
  Products,
  Questionnaires,
  StatusEdit,
  Statuses,
  Subscriptions,
  Tickets,
  Users,
  Vouchers,
  Teams,
  UserEdit,
  UserNew,
} from '../pages/dashboard';

const ElevateAdminRoutes = ({ user }) => {
  return (
    <Routes>
      <Route element={<Layout user={user} />}>
        <Route
          index
          path={BASE_PATH}
          element={
            <ProtectedRoute user={user}>
              <Dashboard />
            </ProtectedRoute>
          }
        />

        <Route
          path={LEADS_PATH}
          element={
            <ProtectedRoute user={user}>
              <Leads />
            </ProtectedRoute>
          }
        />
        <Route
          path={LEAD_DETAIL_PATH}
          element={
            <ProtectedRoute user={user}>
              <LeadDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path={PATIENTS_PATH}
          element={
            <ProtectedRoute user={user}>
              <Patients />
            </ProtectedRoute>
          }
        />
        <Route
          path={PATIENT_DETAIL_PATH}
          element={
            <ProtectedRoute user={user}>
              <PatientDetail />
            </ProtectedRoute>
          }
        />

        <Route
          path={PATIENT_EDIT_PATH}
          element={
            <ProtectedRoute user={user}>
              <PatientEdit />
            </ProtectedRoute>
          }
        />
        <Route
          path={PATIENT_CREATE_PATH}
          element={
            <ProtectedRoute user={user}>
              <PatientCreate />
            </ProtectedRoute>
          }
        />
        <Route
          path={ORDERS_PATH}
          element={
            <ProtectedRoute user={user}>
              <Orders />
            </ProtectedRoute>
          }
        />
        <Route
          path={CASES_PATH}
          element={
            <ProtectedRoute user={user}>
              <Cases />
            </ProtectedRoute>
          }
        />
        <Route
          path={STATUSES_PATH}
          element={
            <ProtectedRoute user={user}>
              <Statuses />
            </ProtectedRoute>
          }
        />
        <Route
          path={STATUS_EDIT_PATH}
          element={
            <ProtectedRoute user={user}>
              <StatusEdit />
            </ProtectedRoute>
          }
        />
        <Route
          path={PRODUCTS_PATH}
          element={
            <ProtectedRoute user={user}>
              <Products />
            </ProtectedRoute>
          }
        />

        <Route
          path={SUBSCRIPTIONS_PATH}
          element={
            <ProtectedRoute user={user}>
              <Subscriptions />
            </ProtectedRoute>
          }
        />
        <Route
          path={USERS_PATH}
          element={
            <ProtectedRoute user={user}>
              <Users />
            </ProtectedRoute>
          }
        />
        <Route
          path={USER_EDIT_PATH}
          element={
            <ProtectedRoute user={user}>
              <UserEdit />
            </ProtectedRoute>
          }
        />
        <Route
          path={USER_CREATE_PATH}
          element={
            <ProtectedRoute user={user}>
              <UserNew />
            </ProtectedRoute>
          }
        />

        <Route
          path={TICKETS_PATH}
          element={
            <ProtectedRoute user={user}>
              <Tickets />
            </ProtectedRoute>
          }
        />
        <Route
          path={QUESTIONNAIRES_PATH}
          element={
            <ProtectedRoute user={user}>
              <Questionnaires />
            </ProtectedRoute>
          }
        />
        <Route
          path={VOUCHERS_PATH}
          element={
            <ProtectedRoute user={user}>
              <Vouchers />
            </ProtectedRoute>
          }
        />
        <Route
          path={TEAMS_PATH}
          element={
            <ProtectedRoute user={user}>
              <Teams />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};

export default ElevateAdminRoutes;
