import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Icon, Modal } from '../../../common';
import { USERS_MODAL_TYPES } from '../../../constants/generic-constants';
import DeleteUserModalBody from './DeleteUserModalBody';

const UserInfoModal = ({
  toggle = false,
  modalType = USERS_MODAL_TYPES.ADD_USER,
  userRoles = [],
  isEdit = false,
  userId = '',
  userData = {},
  setUserModalData = () => {},
  onSuccess = () => {},
}) => {
  const [modalData, setModalData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef(null);

  const toggleModal = ({ toggle = false }) => {
    setUserModalData((prev) => {
      return { ...prev, toggle: toggle };
    });
  };

  const getModalData = ({ type, toggle = false }) => {
    switch (type) {
      case USERS_MODAL_TYPES.DELETE_USER:
        setModalData({
          open: toggle,
          title: 'Delete User',
          Body: (
            <DeleteUserModalBody
              ref={formRef}
              userId={userId}
              onSuccess={() => {
                toggleModal({ toggle: false });
                onSuccess();
              }}
              setIsLoading={setIsLoading}
            />
          ),
          handleOK: () => {
            formRef?.current?.submitForm();
          },
          handleCancel: () => {
            toggleModal({ toggle: false });
          },
          okText: 'Delete',
          width: 580,
          showActions: true,
          maskClosable: true,
          okButtonProps: {
            icon: <Icon icon={'IoTrashBinOutline'} size={'0.8em'} />,
          },
        });
        break;
      default:
        return;
    }
  };

  useEffect(() => {
    getModalData({ type: modalType, toggle: toggle });
  }, [modalType, toggle]);

  return (
    <Fragment>
      <Modal
        maskClosable={modalData?.maskClosable}
        title={modalData?.title}
        open={modalData?.open}
        ModalBody={modalData?.Body}
        handleOk={modalData?.handleOK}
        handleCancel={modalData?.handleCancel}
        width={modalData?.width}
        showActions={modalData?.showActions}
        okText={modalData?.okText}
        modalStyles={modalData?.modalStyles}
        okButtonProps={{ loading: isLoading, ...modalData?.okButtonProps }}
        destroyOnClose
      />
    </Fragment>
  );
};

export default UserInfoModal;
