import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import { Flex } from 'antd';
import * as yup from 'yup';
import toast from 'react-hot-toast';
import { updateStatusDetail } from '../../../api/statuses-api';
import { getErrorString } from '../../../utils';
import { Button, Card, ColorPicker, Input } from '../../../common';

const StatusInfoForm = ({ statusId = '', statusData = {} }) => {
  const formikRef = useRef();
  const navigate = useNavigate();
  const getInitialFormValues = ({ editData = {} }) => {
    return {
      status_name: editData?.name || '',
      status_type: editData?.status_type || '',
      status_color: editData?.color || '',
      status_bg_color: editData?.bg_color || '',
    };
  };

  const getValidationSchema = () => {
    return yup.object().shape({
      status_name: yup.string().required('Status Name is required'),
    });
  };

  const handleEditStatusSubmit = async (values) => {
    if (!statusId) return;
    try {
      const payload = {
        name: values?.status_name || '',
        color: values?.status_color || '',
        bg_color: values?.status_bg_color || '',
      };
      const res = await updateStatusDetail(payload, statusId);
      if (res && res?.status) {
        toast.success(res?.message);
        navigate(-1, { replace: true });
      } else {
        toast.error(getErrorString(res));
      }
    } catch (error) {
      toast.error(getErrorString(error));
    }
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={getInitialFormValues({ editData: statusData })}
      validationSchema={getValidationSchema()}
      onSubmit={handleEditStatusSubmit}
    >
      {(formikProps) => {
        const { values, errors, touched, setFieldValue, handleSubmit, handleBlur, handleChange, isSubmitting } =
          formikProps;
        return (
          <form>
            <Card>
              <Flex vertical gap={25}>
                <Input
                  name="status_name"
                  placeholder="Status Name"
                  label="Status Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.status_name}
                  errorMsg={errors?.status_name && touched?.status_name && errors.status_name}
                />
                <Input
                  name="status_type"
                  placeholder="Status Type"
                  label="Status Type"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.status_type}
                  errorMsg={errors?.status_type && touched?.status_type && errors.status_type}
                  disabled={true}
                />
                <ColorPicker
                  placeholder="Color "
                  label="Color"
                  onChange={(color) => setFieldValue('status_color', color.toHexString())}
                  value={statusData?.color || null}
                />
                <ColorPicker
                  placeholder="Bg Color "
                  label="Background Color"
                  onChange={(color) => setFieldValue('status_bg_color', color.toHexString())}
                  value={statusData?.bg_color || null}
                />
                <Flex justify="end">
                  <Button
                    text="Submit"
                    type={'primary'}
                    size={'large'}
                    style={{ '--ant-button-padding-inline-lg': '36px' }}
                    onClick={handleSubmit}
                    loading={isSubmitting}
                  />
                </Flex>
              </Flex>
            </Card>
          </form>
        );
      }}
    </Formik>
  );
};

export default StatusInfoForm;
