import APIController from '../core-api-controller';

export const fetchLeadsApi = (paramsObj = {}, paramsStr = '') => {
  const path = `${APIController.endpointApi}/leads${paramsStr ? paramsStr : ''}`;
  return APIController.getApiCallback(path, paramsObj);
};

export const fetchOrdersApi = (paramsObj = {}, paramsStr = '') => {
  const path = `${APIController.endpointApi}/orders${paramsStr ? paramsStr : ''}`;
  return APIController.getApiCallback(path, paramsObj);
};

export const syncLeadsApi = (data = {}) => {
  const path = `${APIController.endpointApi}/leads/sync`;
  return APIController.postCallback(path, data);
};

//Detail page Apis

export const fetchLeadDetailApi = (leadId = '', paramsStr = '') => {
  const path = `${APIController.endpointApi}/leads/${leadId}`;
  return APIController.getApiCallback(path);
};

export const updateLeadStatusApi = (leadId = '', data = {}) => {
  const path = `${APIController.endpointApi}/leads/${leadId}`;
  return APIController.putCallback(path, data);
};

export const mergeLeadsApi = (data, leadId = '') => {
  const path = `${APIController.endpointApi}/leads/${leadId}/merge_lead`;
  return APIController.postCallback(path, data);
};

export const createLeadSupportTicket = (data, leadId = '') => {
  const path = `${APIController.endpointApi}/leads/${leadId}/open_ticket`;
  return APIController.postCallback(path, data);
};

export const resolveLeadSupportTicket = (data, leadId = '') => {
  const path = `${APIController.endpointApi}/leads/${leadId}/resolve_ticket`;
  return APIController.postCallback(path, data);
};
