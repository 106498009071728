import React, { Fragment } from 'react';
import { PATIENTS_PATH } from '../../../constants/admin-paths';
import { Flex } from 'antd';
import Breadcrumb from '../../../common/breadcrumb/Breadcrumb';

const PatientDetailHeader = ({ patientDetail = {} }) => {
  const patientFullName = (patientDetail?.first_name || '') + '-' + (patientDetail?.last_name || '');

  return (
    <Fragment>
      <div className="row mx-2">
        <Flex vertical gap={20}>
          <Flex justify="space-between" align="center">
            <Breadcrumb items={[{ title: 'Patients', path: PATIENTS_PATH }, { title: 'Patient Details' }]} />
          </Flex>
          <Flex align="center" gap={15}>
            <div className="heading-1">{patientFullName}</div>
          </Flex>
        </Flex>
      </div>
    </Fragment>
  );
};

export default PatientDetailHeader;
