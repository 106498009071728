import React from 'react';
import DisplayDateTime from '../../../utils/time/display_time';
import DetailCardVertical from '../../detail-card/DetailCardVertical';

const LeadPatient = ({ detailData = {}, mdiSyncedAt = '', samcartSyncedAt = '', leadId = '' }) => {
  const getDetailsData = () => {
    return [
      {
        label: 'Lead ID',
        value: leadId || '-',
        isPrimaryText: true,
      },
      {
        label: 'Gender',
        value: detailData?.gender_label || '-',
      },
      {
        label: 'Dob',
        value: detailData?.date_of_birth || '-',
      },
      {
        label: 'State',
        value: detailData?.address?.state || '-',
      },
      {
        label: 'Postal Code',
        value: detailData?.address?.postal_code || '-',
      },

      {
        label: 'Created at',
        value: DisplayDateTime(detailData?.created_at) || '-',
      },
      {
        label: 'Updated at',
        value: DisplayDateTime(detailData?.updated_at) || '-',
      },
      {
        label: 'MDI synced at',
        value: DisplayDateTime(mdiSyncedAt) || '-',
      },
      {
        label: 'Samcart synced at',
        value: DisplayDateTime(samcartSyncedAt) || '-',
      },
    ];
  };

  return <DetailCardVertical cardTitle={'Lead Details'} cardData={getDetailsData()} />;
};

export default LeadPatient;
