import { Empty, Select, Spin } from 'antd';
import { useEffect, useState } from 'react';
import cx from 'clsx';
import styles from '../select/Select.module.scss';
import { FaChevronDown } from 'react-icons/fa6';
import { convertQueryObjToString, formatOptionsForSelect } from '../../utils/filters-util';
import { fetchSearchDebounce, getErrorString, infiniteScrollApiCall, isNumericString } from '../../utils';
import toast from 'react-hot-toast';
import ErrorMessage from '../error-message/ErrorMessage';
import Label from '../label/Label';
import Skeleton from '../skeleton/Skeleton';

const InfiniteSelect = ({
  placeholder = '',
  loading = false,
  fetchKey = '',
  searchKey = '',
  queryKey = '',
  customRender = '',
  onChange = () => {},
  fetch = async () => {},
  required = false,
  labelSize = '',
  labelClassName = '',
  label = '',
  errorMsg = '',
  className,
  selectSize = 'large',
  mode = 'multiple',
  defaultValue = null,
  handleBlur = () => {},
  disabled = false,
  resetValue = false,
  setter = () => {},
  setAsSlug = false,
  filterKey = '',
  searchEnabled = true,
}) => {
  const [options, setOptions] = useState([]);
  const [initialLoading, setInitialLoading] = useState(false);
  const [scrollLoading, setScrollLoading] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState(mode === 'single' ? undefined : []);
  const [pagination, setPagination] = useState(null);
  const [fetchedTillPage, setFetchedTillPage] = useState({ current_page: 0 });

  const functionsByMode = {
    single: {
      initialValue: () => undefined,
      value: () =>
        selectedValue
          ? {
              ...selectedValue,
              label: selectedValue?.title ?? selectedValue?.name ?? '',
              value: setAsSlug ? selectedValue?.id : selectedValue?.slug,
            }
          : undefined,
      setInitialValues: ({ list }) => {
        const newValue = list.find((item) =>
          setAsSlug ? item?.slug === defaultValue : item?.id === parseInt(defaultValue),
        );
        setSelectedValue(newValue);
        setter([newValue]);
      },
      onSelect: ({ value }) => {
        setSelectedValue(value);
      },
      clearSelect: () => {
        setSelectedValue({});
      },
      onDeselect: ({ item }) => {
        setSelectedValue({});
      },
      handleChange: ({ e }) => {
        onChange(options.find((item) => item.id === e));
      },
    },
    multiple: {
      initialValue: () => [],
      value: () => formatOptionsForSelect({ list: selectedValue, setAsSlug }),
      setInitialValues: ({ list }) => {
        let optionIds = defaultValue.split(',');
        if (isNumericString(optionIds[0])) {
          optionIds = optionIds.map((option) => parseInt(option));
        }
        const newValue = list.filter((item) => optionIds.includes(setAsSlug ? item?.slug : item?.id));
        setSelectedValue(newValue);
        setter(newValue);
      },
      onSelect: ({ value }) => {
        const newValue = [...selectedValue, value];
        setSelectedValue(newValue);
      },
      clearSelect: () => {
        setSelectedValue([]);
      },
      onDeselect: ({ item }) => {
        const filteredArray = selectedValue?.filter((value) => value.id !== item);
        setSelectedValue([...filteredArray]);
        filteredArray?.length === 0 && setSelectedValue([]);
      },
      handleChange: ({ e }) => {
        let optionArray = options.length ? options : selectedValue;
        onChange(optionArray.filter((item) => e.includes(setAsSlug ? item.id : item.slug)));
      },
    },
  };

  const prefill = async () => {
    if (!defaultValue) return setSelectedValue(functionsByMode[mode].initialValue());
    if (options.length) return;
    setInitialLoading(true);
    try {
      const {
        list,
        current_page = 1,
        total_pages = null,
      } = await infiniteScrollApiCall({
        api: fetch,
        query:
          queryKey.length && (defaultValue.length || isNumericString(defaultValue))
            ? convertQueryObjToString({ [queryKey]: defaultValue })
            : '',
        key: fetchKey,
        page: 1,
      });
      setFetchedTillPage({ current_page, total_pages });
      setPagination({ current_page, total_pages });
      const newData = formatOptionsForSelect({
        list: list,
        setAsSlug,
      });
      setOptions(newData);
      functionsByMode[mode].setInitialValues({ list });
    } catch (error) {
      toast.error(getErrorString(error));
    } finally {
      setInitialLoading(false);
    }
  };

  const fetchScrollOptions = async ({ page, query = '' }) => {
    try {
      setFetchLoading(true);
      if (fetch && page) {
        const {
          list,
          current_page = 1,
          total_pages = null,
        } = await infiniteScrollApiCall({
          api: fetch,
          query: query,
          key: fetchKey,
          page: page,
        });

        if (query === '' && current_page > fetchedTillPage?.current_page)
          setFetchedTillPage({ current_page, total_pages });

        setPagination({ current_page, total_pages });
        if (current_page != pagination?.current_page || pagination?.current_page === 1) {
          const oldOptionIds = options.map((option) => option?.id);
          const optionsToAppend = list.filter((item) => !oldOptionIds.includes(item?.id));
          const newData = formatOptionsForSelect({
            list: [...options, ...optionsToAppend],
            setAsSlug,
          });
          setOptions(newData);
          setter && setter(newData);
        }
      }
    } catch (error) {
      setFetchLoading(false);
      toast.error(getErrorString(error));
    } finally {
      setFetchLoading(false);
    }
  };

  const handleInfiniteScroll = (event) => {
    if (scrollLoading) return;
    const target = event.target;
    const isScrollEndOfPage = target.scrollTop + target.offsetHeight >= target.scrollHeight - 50;
    const nextPage = fetchedTillPage.current_page + 1;
    if (isScrollEndOfPage && fetchedTillPage.current_page < fetchedTillPage?.total_pages) {
      setScrollLoading(true);
      setTimeout(() => {
        fetchScrollOptions({ page: nextPage });
        setScrollLoading(false);
      }, 250);
    }
  };

  const handleInputChange = async (value) => {
    if (!value?.length) fetchSearchDebounce({ api: () => fetchScrollOptions({ page: fetchedTillPage?.current_page }) });
    else {
      const query = `${searchKey}=${value}`;
      fetchSearchDebounce({ api: fetchScrollOptions, query });
    }
  };

  const filterOption = (input, option) => {
    return (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  };

  useEffect(() => {
    if (resetValue) {
      setSelectedValue(functionsByMode[mode].initialValue());
      setOptions([]);
      setPagination(null);
      setFetchedTillPage({ current_page: 0 });
    }
  }, [resetValue]);

  useEffect(() => {
    prefill();
  }, []);

  if (loading) return <Skeleton height={40} />;
  return (
    <div>
      {label && (
        <Label size={labelSize} className={labelClassName} required={required}>
          {label}
        </Label>
      )}
      <Select
        className={cx(styles.select, className)}
        value={functionsByMode[mode].value()}
        maxTagCount={'responsive'}
        options={options}
        placeholder={placeholder}
        allowClear={false}
        mode={mode}
        filterOption={filterOption}
        notFoundContent={
          fetchLoading || initialLoading ? (
            <Spin size="small" />
          ) : (
            options?.length === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )
        }
        loading={scrollLoading}
        size={selectSize}
        suffixIcon={<FaChevronDown color="var(--gray-300)" />}
        onClear={() => {
          functionsByMode[mode].clearSelect();
        }}
        onSelect={(value, option) => {
          functionsByMode[mode].onSelect({ value: option });
        }}
        onDeselect={(value) => {
          functionsByMode[mode].onDeselect({ item: value });
        }}
        onSearch={(value) => {
          handleInputChange(value);
        }}
        onPopupScroll={(event) => {
          handleInfiniteScroll(event);
        }}
        onDropdownVisibleChange={(visible) => {
          visible && fetchScrollOptions({ page: pagination?.current_page ?? 1 });
        }}
        onChange={(e, items) => {
          e && functionsByMode[mode].handleChange({ e });
        }}
        showSearch={searchEnabled}
        style={{ border: errorMsg?.length && '0.25px solid #FF5636', borderRadius: '4px' }}
        onBlur={handleBlur}
        disabled={disabled}
        {...(customRender ? { optionRender: (item) => customRender(item) } : {})}
      />
      {errorMsg && <ErrorMessage errorMsg={errorMsg} />}
    </div>
  );
};
export default InfiniteSelect;
