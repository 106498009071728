import APIController from '../core-api-controller';

export const fetchNotesApi = ({ sourceId = '', sourceType = '', paramsStr = '' }) => {
  const path = `${APIController.endpointApi}/notes?q[source_id_eq]=${sourceId}&q[source_type_eq]=${sourceType}&${paramsStr ? paramsStr : ''}`;
  return APIController.getApiCallback(path);
};

export const submitNotesApi = (data) => {
  const path = `${APIController.endpointApi}/notes`;
  return APIController.postCallback(path, data);
};
