import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import { getErrorString } from '../../utils';
import { getQueryString } from '../../utils/filters-util';
import { fetchTeamsListApi } from '../../api/teams-api';

const initialState = {
  activeColumns: [],
  teams: [],
  departments: [],
  loading: true,
  pending: false,
  pagination: {},
};

export const fetchTeamsListing = createAsyncThunk('teams/listing', async (updatedParamsObject = {}) => {
  try {
    const paramsObj = {
      'q[s]': 'created_at desc',
      ...updatedParamsObject,
    };
    const res = await fetchTeamsListApi({}, getQueryString(paramsObj));
    return res;
  } catch (error) {
    toast.error(getErrorString(error));
  }
});

const TeamsSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    setTeamsLoading: (state, action) => {
      state.loading = action?.payload?.loading;
    },
    setTeamsActiveColumn: (state, action) => {
      state.activeColumns = action?.payload?.activeColumns;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchTeamsListing.pending, (state) => {
        state.pending = true;
      })
      .addCase(fetchTeamsListing.fulfilled, (state, action) => {
        if (state.loading) {
          //means first time loading
          state.activeColumns = action?.payload?.active_columns || [];
        }
        state.teams = action?.payload?.data || [];
        state.departments = action?.payload?.departments || [];
        state.loading = false;
        state.pending = false;
        state.pagination = action?.payload?.meta?.pagination || {};
      })
      .addCase(fetchTeamsListing.rejected, (state) => {
        state.loading = false;
        state.pending = false;
      });
  },
});

export const { setTeamsActiveColumn, setTeamsLoading } = TeamsSlice.actions;
export default TeamsSlice.reducer;
