import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { logout } from './logout-action';
import leadsSlice from './leads-slice';
import permissionsSlice from './permissions-slice';
import userSlice from './user-slice';
import quickLinksSlice from './quick-links-slice';
import ticketsSlice from './tickets-slice';
import questionnairesSlice from './questionnaires-slice';
import vouchersSlice from './vouchers-slice';
import patientsSlice from './patients-slice';
import ordersSlice from './orders-slice';
import casesSlice from './cases-slice';
import subscriptionsSlice from './subscriptions-slice';
import productsSlice from './products-slice';
import statusesSlice from './statuses-slice';
import allUsersSlice from './all-users-slice';
import teamsSlice from './teams-slice';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['permissionsData', 'userData'],
};

const reducers = combineReducers({
  permissionsData: permissionsSlice,
  userData: userSlice,
  leadsData: leadsSlice,
  patientsData: patientsSlice,
  ordersData: ordersSlice,
  casesData: casesSlice,
  subscriptionsData: subscriptionsSlice,
  productsData: productsSlice,
  ticketsData: ticketsSlice,
  questionnairesData: questionnairesSlice,
  statusesData: statusesSlice,
  vouchersData: vouchersSlice,
  usersData: allUsersSlice,
  teamsData: teamsSlice,
  quickLinksData: quickLinksSlice,
});

const modifiedReducers = (state, action) => {
  if (logout.match(action)) {
    return reducers(undefined, action); //clears redux state on logout
  }
  return reducers(state, action);
};

export const persistedReducer = persistReducer(persistConfig, modifiedReducers);
