import { debounce } from 'lodash';
import { BASE_PATH } from '../constants/admin-paths';

export const isEmptyObject = (obj) =>
  obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype;

export const isEmptyArray = (arr) => arr && Array.isArray(arr) && arr?.length === 0;

export const getErrorString = (err) => {
  const errorData = err?.response?.data;
  if (!errorData) return err?.response?.statusText || err?.message || err?.response;
  const errorExtracted = errorData?.error || errorData?.message || errorData;

  if (typeof errorExtracted === 'string') return errorExtracted;
  if (errorExtracted?.length) return errorExtracted?.join(', ');
};

export const isFieldRestricted = (field) => {
  if (field) {
    return field === 'Restricted';
  } else {
    return false;
  }
};

export const isNumericString = (string) => {
  if (typeof string === 'number' || string === '') return true;
  const regex = /[^0-9.]/;
  return !regex.test(string);
};

export const getCurrencyString = (string) => {
  return `$${string}`;
};

export const isCtrlClicked = (e) => e.ctrlKey || e.metaKey || e.control || e.detail === 2;

export const openUrlLinkInNewTab = (url = '', isAppUrl = false) => {
  const baseUrl = new URL(window.location.href);
  const routeUrl = isAppUrl ? `${BASE_PATH + url}` : url;
  window.open(baseUrl.origin + routeUrl, '_blank').focus();
};

export const fetchValueByDotOperator = (object, value) => {
  return value.split('.').reduce((acc, curr) => {
    return acc && acc[curr] !== undefined && acc[curr] !== null ? acc[curr] : undefined;
  }, object);
};

export const formateNumberWithCommas = (number) => {
  return number.toLocaleString('en-US');
};

export const fetchSearchDebounce = debounce(async ({ api = async () => {}, query = '' }) => {
  api({ page: 1, query });
}, 300);

export const infiniteScrollApiCall = async ({ api = async () => {}, query = '', key = '', page = 1 }) => {
  const data = await api(query ? `?page=${page}&${query}` : `?page=${page}`);
  if (data[key]) {
    return { list: data[key], current_page: data?.meta?.pagination?.page, total_pages: data?.meta?.pagination?.pages };
  } else {
    return { list: [], current_page: null, total_pages: null };
  }
};
