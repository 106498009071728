import { Fragment, useEffect, useRef } from 'react';
import * as yup from 'yup';
import { Button, Card, Group, Heading, Input, Select } from '../../../common';
import { Tooltip } from 'react-tooltip';
import { formatOptionsForSelect } from '../../../utils/filters-util';
import { Formik, useFormikContext } from 'formik';
import { Flex } from 'antd';
import { getErrorString, isEmptyObject } from '../../../utils';
import { INITIAL_USER_SHIFT_LIST } from '../../../constants/date-constants';
import UserShiftsInput from './UserShiftsInput';
import { extractInitialShifts, makeUserShiftsPayload } from './utils/UserInfoUtils';
import { createUserApi, updateUserApi } from '../../../api/users-api';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { USERS_PATH } from '../../../constants/admin-paths';

const FormikHelpers = ({ userData = {}, getInitialValues = () => {} }) => {
  const { values, setValues } = useFormikContext();

  useEffect(() => {
    if (!isEmptyObject(userData)) {
      const values_payload = {
        ...userData,
        timeSlots: extractInitialShifts({ time_slots: values?.time_slots, dataShifts: userData?.shifts }),
      };
      const payload = getInitialValues(values_payload);
      setValues(payload);
    }
  }, [userData]);

  return null;
};

const UserInfoForm = ({ title = '', userId = '', loading = false, userData = {}, userRoles = [] }) => {
  const formRef = useRef();
  const navigate = useNavigate();

  const getInitialValues = (userData = {}) => {
    return {
      name: userData?.name ?? '',
      email: userData?.email ?? '',
      password: userData?.password ?? '',
      roles: userData?.roles ?? [],
      time_slots: userData?.timeSlots ?? INITIAL_USER_SHIFT_LIST,
    };
  };

  const getValidationSchema = () =>
    yup.object().shape({
      name: yup.string().required('Name is required'),
      email: yup.string().required('Email is required').email('Valid Email is required'),
      password: !userId
        ? yup
            .string()
            .required('Password is required')
            .test('length', 'Minimum 6 characters required', (value) => value?.length > 5)
        : yup.string().test('length', 'Minimum 6 characters required', (value) => (value ? value?.length > 5 : true)),
      roles: yup
        .array()
        .nullable()
        .test('roles', 'Role is required', function (value) {
          return !!this?.parent?.roles?.length > 0;
        }),
    });

  const handleFormSubmit = async (values) => {
    try {
      const shiftsAttributes = makeUserShiftsPayload({ time_slots: values?.time_slots });
      const payload = {
        user: {
          name: values?.name ?? '',
          email: values?.email ?? '',
          role_ids: values?.roles?.map((item) => item?.id) ?? [],
          ...(values?.password ? { password: values?.password ?? '' } : {}),
          ...(shiftsAttributes?.length ? { shifts_attributes: shiftsAttributes } : {}),
        },
      };
      const res = !userId ? await createUserApi(payload) : await updateUserApi(payload, userId);
      if (res && res?.status) {
        toast.success('User Saved Successfully');
        navigate(USERS_PATH);
      } else {
        toast.error(getErrorString(res));
      }
    } catch (error) {
      toast.error(getErrorString(error));
    }
  };

  return (
    <Fragment>
      <Heading level={4} className={'page-title'}>
        {title}
      </Heading>
      <Formik
        innerRef={formRef}
        initialValues={getInitialValues()}
        validationSchema={getValidationSchema()}
        onSubmit={handleFormSubmit}
      >
        {(formikProps) => {
          const { values, errors, touched, handleSubmit, handleBlur, handleChange, isSubmitting, setFieldValue } =
            formikProps;
          return (
            <form>
              <FormikHelpers userData={userData} getInitialValues={getInitialValues} />
              <Card>
                <Flex vertical gap={20}>
                  <Flex vertical gap={15} className="list-item-border-bottom">
                    <Heading level={3} className={'heading-3'}>
                      {'Personal Info'}
                    </Heading>
                    <Group template={'repeat(2, 1fr)'}>
                      <Input
                        placeholder="Enter Name"
                        name="name"
                        label="Name"
                        value={values?.name}
                        errorMsg={errors?.name && touched?.name && errors?.name}
                        onChange={handleChange}
                        onPressEnter={handleSubmit}
                        required
                        loading={loading}
                      />
                      <Input
                        placeholder="Enter Email"
                        name="email"
                        label="Email"
                        value={values?.email}
                        errorMsg={errors?.email && touched?.email && errors?.email}
                        onChange={handleChange}
                        onPressEnter={handleSubmit}
                        required
                        disabled={!!userId}
                        loading={loading}
                      />
                      <Input
                        placeholder={!userId ? 'Enter password' : 'Leave blank to keep current password'}
                        name="password"
                        label={!userId ? 'Password' : 'New Password'}
                        value={values?.password}
                        errorMsg={errors?.password && touched?.password && errors?.password}
                        onChange={handleChange}
                        onPressEnter={handleSubmit}
                        required={!userId}
                        data-tooltip-id={!!userId ? 'edit-password' : ''}
                        loading={loading}
                      />
                      <Tooltip
                        id="edit-password"
                        content={
                          "Leave this field blank if you don't want to update the password. Enter a new password only if you wish to change it"
                        }
                        style={{ zIndex: 99 }}
                      />
                      <Select
                        label={'Roles'}
                        placeholder={'Select Roles'}
                        value={formatOptionsForSelect({ list: values.roles })}
                        options={formatOptionsForSelect({ list: userRoles })}
                        onChange={(options) => {
                          setFieldValue('roles', [...options]);
                        }}
                        onBlur={handleBlur('roles')}
                        errorMsg={errors?.roles && touched?.roles && errors?.roles}
                        mode={'multiple'}
                        required
                        loading={loading}
                      />
                    </Group>
                  </Flex>
                  <Flex vertical gap={25} className="list-item-border-bottom">
                    <UserShiftsInput values={values} setFieldValue={setFieldValue} loading={loading} />
                  </Flex>
                </Flex>
                <Flex align="end" justify="end">
                  <Button
                    text="Submit"
                    type={'primary'}
                    size={'middle'}
                    style={{ '--ant-button-padding-inline-lg': '36px' }}
                    onClick={handleSubmit}
                    loading={isSubmitting}
                  />
                </Flex>
              </Card>
            </form>
          );
        }}
      </Formik>
    </Fragment>
  );
};

export default UserInfoForm;
