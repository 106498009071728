import React from 'react';
import cx from 'clsx';
import { Empty, Flex } from 'antd';
import styles from './DetailCard.module.scss';
import { CopyToClipboard } from '../../common';

const DetailCardRowItem = ({ label = '', value = '', isPrimaryText = false, isTextCopy = false }) => {
  return (
    <Flex vertical gap={15}>
      <span className="text-2">{label}</span>
      <Flex align="center" gap={6}>
        <span className={cx({ ['primary-text']: isPrimaryText, ['subtitle-2']: true })}>{value}</span>
        {isTextCopy && value !== '-' && <CopyToClipboard value={value} />}
      </Flex>
    </Flex>
  );
};

const DetailCardVertical = ({ cardTitle = '', cardData = [], ActionsList = () => { } }) => {
  return (
    <Flex vertical gap={20} className={styles.detailsContainer} style={{ width: '100%' }}>
      <Flex align="center" justify="space-between">
        {cardTitle && <div className="heading-4">{cardTitle}</div>}
        {ActionsList && ActionsList}
      </Flex>
      {cardData?.length ? (
        <Flex vertical gap={20} className={styles.detailsListContainer}>
          {cardData?.map((card, index) => (
            card?.component ?
              card?.component :
              <DetailCardRowItem key={card?.label + index} {...card} />
          ))}
        </Flex>
      ) : (
        <Empty />
      )}
    </Flex>
  );
};

export default DetailCardVertical;
