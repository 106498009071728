export const REQUEST_TYPES = {
  POST: 'post',
  PUT: 'put',
  PATCH: 'patch',
  GET: 'get',
  DELETE: 'delete',
};

export const SIDE_MENU_WIDTH = {
  open: 230,
  close: 72,
};

export const FILTER_TYPES = {
  DROP_DOWN: 'dropdown',
  BOOLEAN: 'boolean',
  DATE: 'date',
  INPUT: 'input',
};

export const SYNC_TYPES = Object.freeze({
  MDI: 'mdi',
  SAMCART: 'samcart',
});

export const USERS_MODAL_TYPES = Object.freeze({
  ADD_USER: 'add_user',
  EDIT_USER: 'edit_user',
  DELETE_USER: 'delete_user',
});

export const TEAMS_MODAL_TYPES = Object.freeze({
  ADD_TEAM: 'add_team',
  EDIT_TEAM: 'edit_team',
  DELETE_TEAM: 'delete_team',
});

export const PRODUCT_MODAL_TYPES = Object.freeze({
  EDIT_PRODUCT: 'edit_product',
});

export const SOURCE_CLASS_NAMES = Object.freeze({
  LEAD: 'Lead',
  PATIENT: 'Patient',
  ORDER: 'Order',
  CASE: 'Case',
  STATUS: 'Status',
  PRODUCT: 'Product',
  SUBSCRIPTION: 'Subscription',
  USER: 'User',
  TICKET: 'Ticket',
  QUESTIONNAIRES: 'Questionnaire',
  VOUCHERS: 'Voucher',
  TEAMS: 'Team',
});
