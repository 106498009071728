import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import { getErrorString } from '../../utils';
import { fetchUsersApi } from '../../api/users-api';
import { getQueryString } from '../../utils/filters-util';

const initialState = {
  activeColumns: [],
  users: [],
  userRoles: [],
  loading: true,
  pending: false,
  pagination: {},
};

export const fetchUsersListing = createAsyncThunk('users/listing', async (updatedParamsObject = {}) => {
  try {
    const paramsObj = {
      'q[s]': 'created_at desc',
      ...updatedParamsObject,
    };
    const res = await fetchUsersApi({}, getQueryString(paramsObj));
    return res;
  } catch (error) {
    toast.error(getErrorString(error));
  }
});

const UsersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUsersLoading: (state, action) => {
      state.loading = action?.payload?.loading;
    },
    setUsersActiveColumn: (state, action) => {
      state.activeColumns = action?.payload?.activeColumns;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUsersListing.pending, (state) => {
        state.pending = true;
      })
      .addCase(fetchUsersListing.fulfilled, (state, action) => {
        if (state.loading) {
          //means first time loading
          state.activeColumns = action?.payload?.active_columns || [];
        }
        state.users = action?.payload?.data || [];
        state.userRoles = action?.payload?.roles || [];
        state.loading = false;
        state.pending = false;
        state.pagination = action?.payload?.meta?.pagination || {};
      })
      .addCase(fetchUsersListing.rejected, (state) => {
        state.loading = false;
        state.pending = false;
      });
  },
});

export const { setUsersActiveColumn, setUsersLoading } = UsersSlice.actions;
export default UsersSlice.reducer;
